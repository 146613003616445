import React from 'react';
import { Layout } from '../components/shared/Layout';

const DisclaimerPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <div className="container mx-auto">
            <h1>Disclaimer</h1>
            <p>
              This Website www.delaplumes.nl (the “<strong>Website</strong>”) is
              property of DELAPLUMES (“<strong>DELAPLUMES</strong>”, “we,” “us”
              or “our”), a collaboration of volunteers having no fixed address.
              DELAPLUMES is a collaboration of volunteers and therefor not
              registered in the Trade registry of the Chamber of Commerce.
            </p>

            <h2>General </h2>
            <p>
              The following is applicable to the Website. By using the Website
              you agree with this disclaimer.
            </p>
            <h2>Content Website</h2>
            <p>
              DELAPLUMES reserves the right to alter the contents of the Website
              and this disclaimer at any time or remove it without having any
              obligation to notify you thereof.
            </p>
            <p>
              DELAPLUMES puts care and attention to the content of the Website.
              However the use of (information on) the Website is at your own
              risk. Despite our efforts, it may occur that the content of the
              Website is incomplete, outdated and/or incorrect. Therefore the
              content of the Website is provided to you without any form of
              guarantee or liability on its accuracy.
            </p>
            <h2>Liability</h2>
            <p>
              DELAPLUMES is not liable for any damage or other harmful
              consequences by the use of (information on) the Website. If you
              undertake any action based on (the information of) our Website,
              such action is undertaken at your own risk. No agreement will be
              concluded between you and DELAPLUMES on the basis of mistakes
              and/or incomplete or outdated information.
            </p>
            <p>
              DELAPLUMES is not liable if the Website is not accessible or
              available due to malfunction (<em>storing</em>) and is not
              responsible or liable for malfunctions in networks of third
              parties, by means of which the access to the Website is obtained.
              In addition, DELAPLUMES is not liable for any content of the
              Website which is provided by third parties.
            </p>
            <p>
              The Website includes hyper links to websites of third parties.
              DELAPLUMES does not accept any liability with respect to (the
              content of) these websites and/or services of third parties.
            </p>
            <h2>Intellectual Property</h2>
            <p>
              All intellectual property rights with respect to the Website
              remain with DELAPLUMES. Without the prior written consent of
              DELAPLUMES it is not allowed to disclose, multifold, save or offer
              for sale (parts of) the Website, information on the Website or
              other material displayed on the Website, in any possible way.
            </p>
            <h2>Contact</h2>
            <p>
              Should you have any further questions and/or comments in relation
              to this disclaimer, you can contact DELAPLUMES via
              info@delaplumes.nl.
            </p>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default DisclaimerPage;
